/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AdUnitsService } from './services/AdUnitsService';
import { DirectSoldAdGroupsService } from './services/DirectSoldAdGroupsService';
import { DirectSoldCampaignsService } from './services/DirectSoldCampaignsService';
import { InventoryService } from './services/InventoryService';
import { MarketplaceService } from './services/MarketplaceService';
import { MonetizationService } from './services/MonetizationService';
import { MonetizationPlanForecastsService } from './services/MonetizationPlanForecastsService';
import { MonetizationPlansService } from './services/MonetizationPlansService';
import { PlacementsService } from './services/PlacementsService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class MonetizationApi {
    public readonly adUnits: AdUnitsService;
    public readonly directSoldAdGroups: DirectSoldAdGroupsService;
    public readonly directSoldCampaigns: DirectSoldCampaignsService;
    public readonly inventory: InventoryService;
    public readonly marketplace: MarketplaceService;
    public readonly monetization: MonetizationService;
    public readonly monetizationPlanForecasts: MonetizationPlanForecastsService;
    public readonly monetizationPlans: MonetizationPlansService;
    public readonly placements: PlacementsService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '2',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.adUnits = new AdUnitsService(this.request);
        this.directSoldAdGroups = new DirectSoldAdGroupsService(this.request);
        this.directSoldCampaigns = new DirectSoldCampaignsService(this.request);
        this.inventory = new InventoryService(this.request);
        this.marketplace = new MarketplaceService(this.request);
        this.monetization = new MonetizationService(this.request);
        this.monetizationPlanForecasts = new MonetizationPlanForecastsService(this.request);
        this.monetizationPlans = new MonetizationPlansService(this.request);
        this.placements = new PlacementsService(this.request);
    }
}

