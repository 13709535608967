/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CopyAdUnitCommandResult } from '../models/CopyAdUnitCommandResult';
import type { ValidateBulkUploadCommandResult } from '../models/ValidateBulkUploadCommandResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdUnitsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param id
     * @returns CopyAdUnitCommandResult OK
     * @throws ApiError
     */
    public postApiMonetizationAdunitsCopy(
        id: number,
    ): CancelablePromise<CopyAdUnitCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/adunits/{id}/copy',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param formData
     * @returns ValidateBulkUploadCommandResult OK
     * @throws ApiError
     */
    public postApiMonetizationAdunitsBulkValidate(
        formData?: {
            adUnitsFile?: Blob;
        },
    ): CancelablePromise<ValidateBulkUploadCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/adunits/bulk/validate',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
