import { createInjectionState } from '@lasso/shared/utils'

import { AxiosInstance } from 'axios'

import { bindApiMethods } from '@lasso/api-shared'

import { useApiCache } from '@lasso/shared/hooks'

import { CancelablePromise, MonetizationApi, OpenAPIConfig } from './generated'
import { AxiosHttpRequest } from './generated/core/AxiosHttpRequest'
import { ApiRequestOptions } from './generated/core/ApiRequestOptions'
import { request } from './generated/core/request'

export const [useProvideMonetizationApi, useMonetizationApi] = createInjectionState(
  (axiosInstance: AxiosInstance, options: Partial<OpenAPIConfig> = {}) => {
    const [{
      adUnits,
      monetization,
      inventory,
      monetizationPlans,
      directSoldAdGroups,
      monetizationPlanForecasts,
      directSoldCampaigns,
      placements,
      marketplace,
    }, methodsMapping] = bindApiMethods(
      new MonetizationApi(
        options,
        class AxiosRequest extends AxiosHttpRequest {
          public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
            return request(this.config, options, axiosInstance)
          }
        },
      ),
    )

    useApiCache().aliasCacheKeys(methodsMapping)

    return {
      getMonetizationGeneralData: monetization.getApiMonetizationGeneral,
      getMonetizationOverviewTotals: monetization.postApiMonetizationOverviewTotals,
      getMonetizationOverviewData: monetization.postApiMonetizationOverview,
      getMonetizationInventoryData: monetization.postApiMonetizationInventory,
      getMonetizationBuyersData: monetization.postApiMonetizationBuyers,
      getMonetizationTrafficData: monetization.postApiMonetizationTraffic,

      getMonetizationTargetingInventory: inventory.postApiMonetizationTargetingInventory,

      getPlans: monetizationPlans.postApiMonetizationPlansMarketer,
      getPlan: monetizationPlans.getApiMonetizationPlans,
      deletePlan: monetizationPlans.deleteApiMonetizationPlans,
      createPlan: monetizationPlans.postApiMonetizationPlans,
      updatePlan: monetizationPlans.putApiMonetizationPlans,
      updatePlanForecast: monetizationPlanForecasts.patchApiMonetizationPlansForecast,

      getDirectSoldCampaigns: directSoldCampaigns.postApiMonetizationDirectsoldCampaigns,
      toggleDirectSoldCampaignFavorite: directSoldCampaigns.putApiMonetizationDirectsoldCampaignsFavorite,
      updateCampaignAdGroups: directSoldCampaigns.postApiMonetizationDirectsoldCampaignsAdgroups,

      getDirectSoldAdGroups: directSoldAdGroups.postApiMonetizationDirectsoldAdgroups,
      toggleDirectSoldAdGroupFavorite: directSoldAdGroups.putApiMonetizationDirectsoldAdgroupsFavorite,
      getDirectSoldAdGroupInventory: inventory.getApiMonetizationTargetingInventoryAdgroup,
      setDirectSoldAdGroupInventory: directSoldAdGroups.putApiMonetizationDirectsoldAdgroupsInventory,
      getDirectSoldAdGroupCreatives: directSoldAdGroups.getApiMonetizationDirectsoldAdgroupsCreative,
      setDirectSoldAdGroupCreatives: directSoldAdGroups.postApiMonetizationDirectsoldAdgroupsCreative,
      getDirectSoldAdGroupPldCreativeCsv: directSoldAdGroups.getApiMonetizationDirectsoldAdgroupsCreativeCsv,

      validateAdUnitsBulk: adUnits.postApiMonetizationAdunitsBulkValidate,
      copyAdUnit: adUnits.postApiMonetizationAdunitsCopy,

      copyPlacement: placements.postApiMonetizationPlacementsCopy,

      getMarketplaceAdGroups: marketplace.postApiMonetizationMarketplaceAdgroups,
    }
  },
  { throwWhenNotProvided: 'Monetization API was not provided' },
)
