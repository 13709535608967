/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyersRequest } from '../models/BuyersRequest';
import type { BuyersResponse } from '../models/BuyersResponse';
import type { GeneralResponse } from '../models/GeneralResponse';
import type { InventoryRequest } from '../models/InventoryRequest';
import type { InventoryResponse } from '../models/InventoryResponse';
import type { OverviewRequest } from '../models/OverviewRequest';
import type { OverviewResponse } from '../models/OverviewResponse';
import type { OverviewTotalsRequest } from '../models/OverviewTotalsRequest';
import type { OverviewTotalsResponse } from '../models/OverviewTotalsResponse';
import type { TrafficRequest } from '../models/TrafficRequest';
import type { TrafficResponse } from '../models/TrafficResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MonetizationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns GeneralResponse OK
     * @throws ApiError
     */
    public getApiMonetizationGeneral(): CancelablePromise<GeneralResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Monetization/general',
        });
    }
    /**
     * @param requestBody
     * @returns OverviewTotalsResponse OK
     * @throws ApiError
     */
    public postApiMonetizationOverviewTotals(
        requestBody?: OverviewTotalsRequest,
    ): CancelablePromise<OverviewTotalsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Monetization/overview-totals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns OverviewResponse OK
     * @throws ApiError
     */
    public postApiMonetizationOverview(
        requestBody?: OverviewRequest,
    ): CancelablePromise<OverviewResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Monetization/overview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns InventoryResponse OK
     * @throws ApiError
     */
    public postApiMonetizationInventory(
        requestBody?: InventoryRequest,
    ): CancelablePromise<InventoryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Monetization/inventory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns BuyersResponse OK
     * @throws ApiError
     */
    public postApiMonetizationBuyers(
        requestBody?: BuyersRequest,
    ): CancelablePromise<BuyersResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Monetization/buyers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns TrafficResponse OK
     * @throws ApiError
     */
    public postApiMonetizationTraffic(
        requestBody?: TrafficRequest,
    ): CancelablePromise<TrafficResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Monetization/traffic',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
