/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LevelType {
    DEFAULT = 'default',
    MARKETER = 'marketer',
    PUBLISHER = 'publisher',
    PLACEMENT = 'placement',
    BRAND = 'brand',
    MEDIA_TYPE = 'media-type',
}
