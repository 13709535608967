/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CopyPlacementCommandResult } from '../models/CopyPlacementCommandResult';
import type { GetShellPlacementsQueryResult } from '../models/GetShellPlacementsQueryResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PlacementsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param id
     * @returns CopyPlacementCommandResult OK
     * @throws ApiError
     */
    public postApiMonetizationPlacementsCopy(
        id: number,
    ): CancelablePromise<CopyPlacementCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/placements/{id}/copy',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param marketerId
     * @param publisherId
     * @returns GetShellPlacementsQueryResult OK
     * @throws ApiError
     */
    public getApiMonetizationPlacementsShell(
        marketerId: number,
        publisherId?: number,
    ): CancelablePromise<GetShellPlacementsQueryResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/monetization/placements/shell/{marketerId}',
            path: {
                'marketerId': marketerId,
            },
            query: {
                'publisherId': publisherId,
            },
        });
    }
}
