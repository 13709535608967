/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AccountOptionType {
    MEDIA_CHANNEL_TYPES = 'media-channel-types',
    PUBLISHER_ENABLED = 'publisher-enabled',
    DATA_ONLY_USERS_ACCESS = 'data-only-users-access',
    BYPASS_DATA_ONLY_VALIDATORS = 'bypass-data-only-validators',
    ENDEMIC_BLEND = 'endemic-blend',
    PLD_ONLY_DIRECT_SOLD_AD_GROUPS = 'pld-only-direct-sold-ad-groups',
}
