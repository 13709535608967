/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCampaignsListQuery } from '../models/GetCampaignsListQuery';
import type { GetCampaignsListQueryResult } from '../models/GetCampaignsListQueryResult';
import type { PXCommandResult } from '../models/PXCommandResult';
import type { UpdateAdGroupsCommand } from '../models/UpdateAdGroupsCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DirectSoldCampaignsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns GetCampaignsListQueryResult OK
     * @throws ApiError
     */
    public postApiMonetizationDirectsoldCampaigns(
        requestBody?: GetCampaignsListQuery,
    ): CancelablePromise<GetCampaignsListQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/directsold/campaigns',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public putApiMonetizationDirectsoldCampaignsFavorite(
        id: number,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/monetization/directsold/campaigns/{id}/favorite',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiMonetizationDirectsoldCampaignsAdgroups(
        id: number,
        requestBody?: UpdateAdGroupsCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/monetization/directsold/campaigns/{id}/adgroups',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
